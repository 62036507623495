import React from 'react';
import { NavLink } from 'react-router-dom';
import '../css/Footer.css';
import logo from '../Components/Images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faYoutube, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-navy text-white py-5 mt-5">
      <div className="container">
        <div className="row">
          {/* Address Section */}
          <div className="col-lg-3 col-md-6 col-sm-12 mb-4 text-lg-left">
            <img src={logo} alt="logo" className="mb-3" />
            <p>Head Office: Office 316, Floor F1, Jeff Heights Gulberg III, Lahore.</p>
            <p>LAHORE | ISLAMABAD | KARACHI</p>
            <p>GILGIT | PESHAWAR</p>
          </div>

          {/* Quick Links Section */}
          <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><NavLink to='/' className="text-white text-decoration-none">Home</NavLink></li>
              <li><NavLink to='/About' className="text-white text-decoration-none">About Us</NavLink></li>
              <li><NavLink to='/Contact' className="text-white text-decoration-none">Get A Quote</NavLink></li>
              <li><NavLink to='/Contact' className="text-white text-decoration-none">Contact</NavLink></li>
            </ul>
          </div>

          {/* Important Links Section */}
          <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
            <h5>Important Links</h5>
            <ul className="list-unstyled">
              <li><NavLink to='https://amdcclinic.com/wp/#' className="text-white text-decoration-none">AMDC</NavLink></li>
              <li><NavLink to='/OurPartners' className="text-white text-decoration-none">Partners</NavLink></li>
            </ul>
          </div>

          {/* Let's Connect Section */}
          <div className="col-lg-3 col-md-6 col-sm-12 mb-4  text-lg-left">
            <h5>Let’s Connect</h5>
            <p>Connect with Digitel, build your network, make great business.</p>
            <div className="d-flex justify-content-center justify-content-lg-start">
              <NavLink to='https://www.facebook.com/DigiTelGlobe' className="text-white mx-2">
                <FontAwesomeIcon icon={faFacebook} size="lg" />
              </NavLink>
              <NavLink to='https://twitter.com/digitelglobe' className="text-white mx-2">
                <FontAwesomeIcon icon={faXTwitter} size="lg" />
              </NavLink>
              <NavLink to='https://www.youtube.com/@digitelglobe' className="text-white mx-2">
                <FontAwesomeIcon icon={faYoutube} size="lg" />
              </NavLink>
              <NavLink to='#' className="text-white mx-2">
                <FontAwesomeIcon icon={faLinkedin} size="lg" />
              </NavLink>
              <NavLink to='https://www.instagram.com/digitelglobe/?hl=en' className="text-white mx-2">
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
