import emailjs from 'emailjs-com';
import React from 'react';
import '../css/ContactForm.css';

export default function ContactForm() {
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_f0zwryl', 'template_whn2e5t', e.target, 'OgLIt7iBjdhblKoKy')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
            e.target.reset()
    }

    return(
        <div className='form-container'>
        <form onSubmit={sendEmail}>
        <input className='inputal' type="text" placeholder='Your Name' name='name' required />
        <br />
        <input className='inputal' type="email" placeholder='Your email' name='email' required/>
        <br />
        <textarea className='inputal' placeholder='message' maxLength='500' name='message' required></textarea>
        <br /><br />
        <button className='buttonal' type='Submit' value='Send Message'>Submit</button>
        </form>
      </div>
    )
}