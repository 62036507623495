import React from 'react'
import '../css/Main.css'
import mainimage from './Images/main.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faUser, faGaugeHigh, faBurger } from '@fortawesome/free-solid-svg-icons';
import technologyimage from './Images/datacenter.png'
import ssmimage from './Images/socialmediamarketing.png'
import servicesimage from './Images/services.jpg'
import automationimage from './Images/automation.png'
import ahf from './Images/Customers/AHF.jpg'
import aiou from './Images/Customers/AIOU.png'
import anp from './Images/Customers/ANP.jpg'
import bi from './Images/Customers/BI.png'
import bu from './Images/Customers/BU.png'
import cottonweb from './Images/Customers/COTTONWEB.jpg'
import imgc from './Images/Customers/IMGC.png'
import jr from './Images/Customers/JR.png'
import kayseria from './Images/Customers/KAYSERIA.jpg'
import kiu from './Images/Customers/KIU-Logo.jpg'
import lci from './Images/Customers/LCI.png'
import leisureclub from './Images/Customers/leisure club.jpg'
import lums from './Images/Customers/LUMS.png'
import mg from './Images/Customers/MG.png'
import morinaga from './Images/Customers/MORINAGA.png'
import ngc from './Images/Customers/NGC.png'
import ntdc from './Images/Customers/NTDC.jpg'
import nu from './Images/Customers/NU.png'
import pesco from './Images/Customers/PESCO.png'
import pmd from './Images/Customers/PMD.jpg'
import rr from './Images/Customers/R&R.png'
import sefam from './Images/Customers/SEFAM.jpg'
import shama from './Images/Customers/SHAMA.png'
import ss from './Images/Customers/SS.png'
import st from './Images/Customers/ST.jpg'
import texaslogo from './Images/Customers/texas logo.png'
import texpak from './Images/Customers/TEXPAK.jpg'
import uop from './Images/Customers/UOP.png'
import wapda from './Images/Customers/WAPDA.jpg'
import willshire from './Images/Customers/wilshire.png'
import location from './Images/digitel location.png'
import {Link} from 'react-router-dom'
import aepl from '../Components/Images/Achievements/AEPL.jpg'
import aioup from '../Components/Images/Achievements/aiou.jpg'
import bup from '../Components/Images/Achievements/bahria university.jpg'
import cwp from '../Components/Images/Achievements/cotton web.jpg'
import cwlp from '../Components/Images/Achievements/cwl.jpg'
import dellemcp from '../Components/Images/Achievements/dellemc.jpg'
import grandstreamp from '../Components/Images/Achievements/grandstream.jpg'
import h3cp from '../Components/Images/Achievements/h3c.jpg'
import lcip from '../Components/Images/Achievements/lci.jpg'
import lumsp from '../Components/Images/Achievements/lums.jpg'
import ntdcp from '../Components/Images/Achievements/ntdc.jpg'
import nap from '../Components/Images/Achievements/nust attom.jpg'
import nustp from '../Components/Images/Achievements/NUST.jpg'
import pescop from '../Components/Images/Achievements/pesco.jpg'
import pmdp from '../Components/Images/Achievements/pmd.jpg'
import riphah from '../Components/Images/Achievements/riphah.jpg'
import ssp from '../Components/Images/Achievements/sanstorage.jpg'
import sap from '../Components/Images/Achievements/seedsacademia.jpg'
import sefamp from '../Components/Images/Achievements/sefam.jpg'
import sophosp from '../Components/Images/Achievements/sophos.jpg'  
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const Main = () => {
  useEffect(() => {
    AOS.init({ duration: 3000 }); 
  }, []);
  return (
    <div data-aos="fade-up">
      <div className='mainimagediv'>
        <img className='mainimage' src={mainimage} alt="mainimage" />
      </div>

      <div className='partnerinbusinessgrowthdiv' data-aos="fade-up" data-aos-delay="500">
        <h1 style={{color: 'navy'}}>Your partner in business growth</h1>
        <p style={{fontFamily: 'sans-serif', fontSize: '120%'}}>Digitel Integrated Solutions, an initiative of DigiTel Group Of Companies, is your dedicated partner in digital era. Commited to provide <b style={{color: 'navy'}}>Reliability, Availability</b> and <b style={{color: 'navy'}}>Securtiy</b>, we collaborate with global IT partners to drive business growth and transformation for our valued clients. Specializing in <b style={{color: 'navy'}}>Technology transformation, Solution upgrades, Mobility, Consolidation, Convergence</b> and <b style={{color: 'navy'}}>Automation</b>, we guide our customers in a journey from manual to automated journey, fostering a virtual state. Join us on the fast track to advancement - where affordability meets innovation</p>
      </div>
      
      <div className='tssadiv' data-aos="fade-up" data-aos-delay="500">
        <div className='technologydiv' data-aos="fade-up" data-aos-delay="500">
        <FontAwesomeIcon style={{color: 'white', fontSize: '200%', marginTop: '5%'}} className='clock' icon={faClock} />
          <h1 style={{fontFamily: 'sans-serif', color: 'white', marginTop: '5%'}}>Technology</h1>
        </div>
        <div className='ssmdiv' data-aos="fade-up" data-aos-delay="500">
          <FontAwesomeIcon style={{color: 'white', fontSize: '200%', marginTop: '5%'}} className='user' icon={faUser}></FontAwesomeIcon>
          <h1 style={{fontFamily: 'sans-serif', color: 'white', marginTop: '5%'}}>Social Media Marketing (SMM)</h1>
        </div>
        <div className='servicesanddeploymentdiv' data-aos="fade-up" data-aos-delay="500">
          <FontAwesomeIcon style={{color: 'white', fontSize: '200%', marginTop: '5%'}} className='gauge' icon={faGaugeHigh}></FontAwesomeIcon>
          <h1 style={{fontFamily: 'sans-serif', color: 'white', marginTop: '5%'}}>Services & Deployment</h1>
        </div>
        <div className='automationdiv' data-aos="fade-up" data-aos-delay="500">
          <FontAwesomeIcon style={{color: 'white', fontSize: '200%', marginTop: '5%'}} className='burger' icon={faBurger}></FontAwesomeIcon>
          <h1 style={{fontFamily: 'sans-serif', color: 'white', marginTop: '5%'}}>Automation</h1>
        </div>
      </div>

      <div className='technologypointsdiv' data-aos="fade-up" data-aos-delay="500">
        <div className='technologypoints'>
          <h1 style={{color: 'white', marginTop: '14%', fontFamily: 'sans-serif'}}>Technology</h1>
          <ul>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Servers and Storage</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Network & Security Solutions</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Backup & Protection</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>IT Infrastructure and Solutions</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Datacenter Establishment</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>IP Telephony, Video Confrencing and Communication based Solutions</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Business Consultancy and DR Methadology</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Surveilance and Monitoring Solution</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Attendance Management Appliances</li>
          </ul>
        </div>
        <div className='technologyimage'>
          <img className='ti' src={technologyimage} alt="technologyimage" />
        </div>
      </div>

      <div className='ssmpointsdiv' data-aos="fade-up" data-aos-delay="500">
        <div className='ssmimage'>
          <img className='ssmimg' src={ssmimage} alt="ssmimage" />
        </div>
        <div className='ssmpoints'>
          <h1 style={{color: 'white', marginTop: '14%', fontFamily: 'sans-serif'}}>Social Media Marketing</h1>
          <ul>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Image Building & Recognition</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Revamping & Rebranding</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Social Media & DigiTel Marketing</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Pages & Group Management</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Graphic Designing & Content writing</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Campaigns and Promotion</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Digital and Corporate Profile Building</li>
          </ul>
        </div>
      </div>

      <div className='servicespointsdiv' data-aos="fade-up" data-aos-delay="500">
        <div className='servicespoints'>
        <h1 style={{color: 'white', marginTop: '12%', fontFamily: 'sans-serif'}}>Service</h1>
        <ul>
          <li style={{color: 'white', fontFamily: 'sans-serif'}}>Deployment & Integration Services</li>
          <li style={{color: 'white', fontFamily: 'sans-serif'}}>Renewals, Upgrades & Tech refreshes</li>
          <li style={{color: 'white', fontFamily: 'sans-serif'}}>Subscriptions and extended warranties</li>
          <li style={{color: 'white', fontFamily: 'sans-serif'}}>Outsourcing, Helpdesk services & O & M</li>
          <li style={{color: 'white', fontFamily: 'sans-serif'}}>Project Management & Quality Assurance</li>
          <li style={{color: 'white', fontFamily: 'sans-serif'}}>Customer Care & Satisfaction</li>
          <li style={{color: 'white', fontFamily: 'sans-serif'}}>Remote or International deployment</li>
          <li style={{color: 'white', fontFamily: 'sans-serif'}}>Videos, illusrations and Vlog Services</li>
        </ul>
        </div>
        <div className='servicesimage'>
        <img className='servicesimg' src={servicesimage} alt="servicesimage" />
      </div>
      </div>

      <div className='automationpointsdiv' data-aos="fade-up" data-aos-delay="500">
        <div className='automationimage'>
          <img className='automationimg' src={automationimage} alt="automationimage" />
        </div>
        <div className='automationpoints'>
          <h1 style={{color: 'white', marginTop: '6%', fontFamily: 'sans-serif'}}>Automation</h1>
          <ul>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Software development</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Web Portals and E-commerce sites</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Development of Online Selling Portal</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>App Development & Publishing</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Hospital Management Solutions (HMIS)</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Attandance & Payroll Management</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Learning Management Solutions</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Search Engine Optimization (SEO)</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Customized ERP and CRMs</li>
            <li style={{color: 'white', fontFamily: 'sans-serif'}}>Cloud Services & Hosting</li>
          </ul>
        </div>
      </div>

      <h1 style={{marginTop:'5%', textAlign:'center', color:'navy'}}>Our Customers</h1>
      <hr />
      <div className='ourcustomersdiv' data-aos="fade-up" data-aos-delay="500">
        <div className='row1'>
          <div className='box1'><img className='ahfimg' src={ahf} alt="" /></div>
          <div className='box2'><img className='aiouimg' src={aiou} alt="aiou" /></div>
          <div className='box3'><img className='anpimg' src={anp} alt="anp" /></div>
          <div className='box4'><img className='biimg' src={bi} alt="bi" /></div>
          <div className='box5'><img className='buimg' src={bu} alt="bu" /></div>
          <div className='box6'><img className='cottonwebimg' src={cottonweb} alt="cottonweb" /></div>
          <div className='box7'><img className='imgcimg' src={imgc} alt="imgc" /></div>
        </div>
        <div className='row2'>
          <div className='box1'><img className='jrimg' src={jr} alt="jr" /></div>
          <div className='box2'><img className='kayseriaimg' src={kayseria} alt="kayseria" /></div>
          <div className='box3'><img className='kiuimg' src={kiu} alt="kiu" /></div>
          <div className='box4'><img className='lciimg' src={lci} alt="lci" /></div>
          <div className='box5'><img className='leisureclubimg' src={leisureclub} alt="leisureclub" /></div>
          <div className='box6'><img className='lumsimg' src={lums} alt="lums" /></div>
          <div className='box7'><img className='mgimg' src={mg} alt="mg" /></div>
        </div>
        <div className='row3'>
          <div className='box1'><img className='morinagaimg' src={morinaga} alt="morinaga" /></div>
          <div className='box2'><img className='ngcimg' src={ngc} alt="ngc" /></div>
          <div className='box3'><img className='ntdcimg' src={ntdc} alt="ntdc" /></div>
          <div className='box4'><img className='nuimg' src={nu} alt="nu" /></div>
          <div className='box5'><img className='pescoimg' src={pesco} alt="pesco" /></div>
          <div className='box6'><img className='pmdimg' src={pmd} alt="pmd" /></div>
          <div className='box7'><img className='rrimg' src={rr} alt="rr" /></div>
        </div>
        <div className='row4'>
          <div className='box1'><img className='sefamimg' src={sefam} alt="sefam" /></div>
          <div className='box2'><img className='shamaimg' src={shama} alt="shama" /></div>
          <div className='box3'><img className='ssimg' src={ss} alt="ss" /></div>
          <div className='box4'><img className='stimg' src={st} alt="st" /></div>
          <div className='box5'><img className='texaslogoimg' src={texaslogo} alt="texaslogo" /></div>
          <div className='box6'><img className='texpakimg' src={texpak} alt="texpak" /></div>
          <div className='box7'><img className='uopimg' src={uop} alt="uop" /></div>
        </div>
        <div className='row5'>
          <div className='box1'><img className='wapdaimg' src={wapda} alt="wapda" /></div>
          <div className='box2'><img className='willshireimg' src={willshire} alt="willshire" /></div>
        </div>
      </div>
      <h1 style={{marginTop:'5%', textAlign:'center', color:'navy'}}>Our Projects</h1>
      <hr />
      <div className='ourprojectsdiv' data-aos="fade-up" data-aos-delay="500">
        <h1></h1>
        <div className='pr1'>
          <div className='prb1'><img className='aepl' src={aepl} alt="aepl" /></div>
          <div className='prb1'><img className='aioup' src={aioup} alt="aioup" /></div>
          <div className='prb1'><img className='bup' src={bup} alt="bup" /></div>
          <div className='prb1'><img className='cwp' src={cwp} alt="cwp" /></div>
        </div>

        <div className='pr1'>
          <div className='prb1'><img className='cwlp' src={cwlp} alt="cwlp" /></div>
          <div className='prb1'><img className='dellemcp' src={dellemcp} alt="dellemcp" /></div>
          <div className='prb1'><img className='grandstreamp' src={grandstreamp} alt="grandstreamp" /></div>
          <div className='prb1'><img className='h3cp' src={h3cp} alt="h3cp" /></div>
        </div>

        <div className='pr1'>
          <div className='prb1'><img className='lcip' src={lcip} alt="lcip" /></div>
          <div className='prb1'><img className='lumsp' src={lumsp} alt="lumsp" /></div>
          <div className='prb1'><img className='ntdcp' src={ntdcp} alt="ntdcp" /></div>
          <div className='prb1'><img className='nap' src={nap} alt="nap" /></div>
        </div>
      </div>

      <div className='pr1'>
        <div className='prb1'><img className='nustp' src={nustp} alt="nust" /></div>
        <div className='prb1'><img className='pescop' src={pescop} alt="pescop" /></div>
        <div className='prb1'><img className='pmdp' src={pmdp} alt="pmdp" /></div>
        <div className='prb1'><img className='riphah' src={riphah} alt="riphah" /></div>
      </div>

      <div className='pr1'>
        <div className='prb1'><img className='ssp' src={ssp} alt="ssp" /></div>
        <div className='prb1'><img className='sap' src={sap} alt="sap" /></div>
        <div className='prb1'><img className='sefamp' src={sefamp} alt="sefamp" /></div>
        <div className='prb1'><img className='sophosp' src={sophosp} alt="sophosp" /></div>
      </div>

      <h1 style={{marginTop:'5%', textAlign:'center', color:'navy'}}>Our Location</h1>
      <hr />
      <div className='locationdiv' data-aos="fade-up" data-aos-delay="500">
        <Link to='https://maps.app.goo.gl/RxM7AkCXHLGZqYz46'><img className='locationimg' src={location} alt="location" /></Link>
      </div>
    </div>
  )
}

export default Main;