import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import '../css/About.css'
import missionimg from '../Components/Images/About us/mission.jpg'
import visionimg from '../Components/Images/About us/vision.png'
import year from '../Components/Images/About us/growth.png'
import goc from '../Components/Images/About us/company.png'
import noticaleimg from '../Components/Images/About us/project.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 3000 }); 
  }, []);
  return (
    <div>
      <Navbar/>
        <h1 style={{textAlign: 'center', color: 'navy', fontFamily: 'sans-serif', marginTop: '2%'}} data-aos="fade-up" data-aos-delay="500">About Us</h1>
        <hr />

        <div className='missionstatementdiv' data-aos="fade-up" data-aos-delay="500">
          <div className='missionstatement'>
            <h1 style={{fontFamily: 'sans-serif'}}>Mission Statement</h1>
            <p style={{fontFamily: 'sans-serif'}}>To become the most affordable, reliable and secure DigiTel platforms that will help it's customers and partners in the growth of business while opting for DigiTel practices. We will remain commited to provide IT solutions primarily focusing on Technology Transformation, Solution Upgrades Mobility, Consolidation, Convergence and Automation</p>
          </div>
          <div className='missionimage'>
            <img className='missionimg' src={missionimg} alt="missionimg" />
          </div>
        </div>

        <div className='visionstatementdiv' data-aos="fade-up" data-aos-delay="500">
          <div className='visionimage'>
            <img className='visionimg' src={visionimg} alt="vision" />
          </div>
          <div className='visionstatement'>
            <h1 style={{fontFamily: 'sans-serif'}}>Vision Statement</h1>
            <p style={{fontFamily: 'sans-serif'}}>To have word "Digital" replaced by term "DigiTel" by providing state of the art solutions to customers. Over the period of time, we vow to bring all our "Manual" customers to "Online" state, while partnering with them in their "Physical" to "Virtual" clloud initiatives.</p>
          </div>
        </div>

        <div className='yearonyeargrowthdiv' data-aos="fade-up" data-aos-delay="500">
          <div className='yearonyeargrowthimage'>
            <img className='yearimg' src={year} alt="year" />
          </div>
          <div className='yearonyeargrowth'>
            <h1 style={{fontFamily: 'sans-serif', color: 'white'}}>Year On Year Growth</h1>
            <ul>
              <li style={{color: 'white', fontFamily: 'sans-serif'}}>Established in 2017, <b>DigiTel Group</b> was an online store and healthcare company</li>
              <li style={{color: 'white', fontFamily: 'sans-serif'}}><b>DigiTel Globe</b> started with only two Business Units (BU) Healthcare and DigiTel Marketing</li>
              <li style={{color: 'white', fontFamily: 'sans-serif'}}>In 2020 company expanded and Pvt.Ltd firm emerged as <b>DigiTel Integrated Solutions Pvt.Ltd</b></li>
              <li style={{color: 'white', fontFamily: 'sans-serif'}}>FY 2020-2021 company gained Top industry & Technology partnerships.</li>
              <li style={{color: 'white', fontFamily: 'sans-serif'}}>In the spring of 2021 UK based <b>Strong Start</b>, partners, up with a digital learning platform to start business communication, <b>IELTS, Cultural Adaptability, PMP, Cisco, AWS</b> cloud practitioner courses, certification preparations and hands-on on-prem and online training.</li>
              <li style={{color: 'white', fontFamily: 'sans-serif'}}>At the start of the year 2022, <b>DigiTel</b> opens up a branch in <b>Gilgit</b> to support and serve the developing Gilgit Baltistan market</li>
            </ul>
          </div>
        </div>

        <div className='groupofcomapniesdiv' data-aos="fade-up" data-aos-delay="500">
          <div className='groupofcompanies'>
            <h1 style={{fontFamily: 'sans-serif', color: 'white'}}>The Group Of Companies</h1>
            <ul>
              <li style={{fontFamily: 'sans-serif', color: 'white'}}><b>DigiTel Integrated Solutions (Pvt) Ltd</b> - Technology & Solutions</li>
              <li style={{fontFamily: 'sans-serif', color: 'white'}}><b>DigiTel Globe</b> - Services, End User Compute, Services, O & M</li>
              <li style={{fontFamily: 'sans-serif', color: 'white'}}><b>AMDC</b> - Dental & Cosmetic Clinics</li>
              <li style={{fontFamily: 'sans-serif', color: 'white'}}><b>Strong Start</b> - A digital learning platform</li>
              <li style={{fontFamily: 'sans-serif', color: 'white'}}><b>D4Dkan</b> - DigiTel stores</li>
            </ul>
          </div>
          <div className='groupofcompaniesimage'>
            <img className='gocimg' src={goc} alt="goc" />
          </div>
        </div>

        <div className='noticaleprojectsdiv' data-aos="fade-up" data-aos-delay="500">
          <div className='noticaleprojectsimage'>
            <img className='noticaleimg' src={noticaleimg} alt="noticaleimg" />
          </div>
          <div className='noticaleprojects'>
            <h1 style={{fontFamily: 'sans-serif', color: 'white'}}>Noticale Projects</h1>
            <ul>
              <li style={{fontFamily: 'sans-serif', color: 'white'}}><b>SEFAM/Kayseria</b> - Smart Modular Datacenter at 8-Waris Rd Lahore first self-containment racking solution of region deployed with <b>ATTOM Technology</b></li>
              <li style={{fontFamily: 'sans-serif', color: 'white'}}><b>National University of Science & Technology (NUST)</b> - Modular Datacenter and Network Infrastructure at NUST Quetta Campus. Largest <b>ATTOM deployment till date.</b></li>
              <li style={{fontFamily: 'sans-serif', color: 'white'}}><b>Karakoram International University (KIU)</b> - Modular Datacenter and Network Infrastructure at <b>Gilgit Campus</b></li>
              <li style={{fontFamily: 'sans-serif', color: 'white'}}><b>National Transmission & Despatch Company (NTDC)</b> - Provision if IT equipment and infrastructure</li>
              <li style={{fontFamily: 'sans-serif', color: 'white'}}><b>Cotton Web Limited</b> - Deployment of SAN Solution at Primary and <b>DR</b> site using <b>DELL EMC</b> PowerStore 500</li>
              <li style={{fontFamily: 'sans-serif', color: 'white'}}><b>Willshire Labs Pvt Ltd</b> - Enhanced security & Intrusion prevention for Datacenter (based on <b>Sophos Network Security Solution)</b></li>
              <li style={{fontFamily: 'sans-serif', color: 'white'}}><b>FBR</b> - Switching and Networking project at <b>RTO Faisalabad</b></li>
              <li style={{fontFamily: 'sans-serif', color: 'white'}}><b>Cotton Web Limited</b> - Internet Access Management and Security Project (based on <b>Sangfor Technologies</b>)</li>
              <li style={{fontFamily: 'sans-serif', color: 'white'}}><b>North Texas Diabetes & Endocrinology Hospital, USA</b> - Video Consultancy and Video Documentary Projects for patient care and awareness</li>
              <li style={{fontFamily: 'sans-serif', color: 'white'}}><b>WAPDA</b> - Provision of IT hardware</li>
              <li style={{fontFamily: 'sans-serif', color: 'white'}}><b>Pakistan MNP Database (Gurantee) Ltd, Islamabad</b> - Relocation of DR site, Designing of new site, Migration of all equipment in tumkey solution</li>
              <li style={{fontFamily: 'sans-serif', color: 'white'}}><b>Allama Iqbal Open University (AIOU) Islamabad</b> - Provisioning of Voice over IP Telephony Solution</li>
              <li style={{fontFamily: 'sans-serif', color: 'white'}}><b>Albario Engineering Private Limited (AEPL)</b> - Provision of IT quipment for manufacturing & design facility</li>
            </ul>
          </div>
        </div>
      <Footer/>
    </div>
  )
}

export default About