import { Footer, Main, Navbar, SplashScreen } from '../Components'
import React, { useState, useEffect } from 'react';

const Home = () => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    // 5 seconds ka timeout set kar rahe hain splash screen ke liye
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 5000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);

  return (
    <>
      {!showContent ? (
        <SplashScreen />
      ) : (
        <>
          <Navbar />
          <Main />
          <Footer />
        </>
      )}
    </>
  );
};

export default Home;
