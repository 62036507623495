import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import '../css/ourpartners.css'
import alhua from '../Components/Images/partners/alhua.jpeg'
import attom from '../Components/Images/partners/ATTOM.jpeg'
import awan from '../Components/Images/partners/AWAN.jpeg'
import aws from '../Components/Images/partners/AWS.png'
import brocade from '../Components/Images/partners/BROCADE.png'
import citrix from '../Components/Images/partners/CITRIX.png'
import cloud9 from '../Components/Images/partners/CLOUD9.png'
import dellemc from '../Components/Images/partners/DELLEMC.png'
import delltechnologies from '../Components/Images/partners/DELLtechnologies.jpeg'
import digitus from '../Components/Images/partners/DIGITUS.png'
import fortinet from '../Components/Images/partners/FORTINET.png'
import grandstream from '../Components/Images/partners/GRANDSTREAM.png'
import h3c from '../Components/Images/partners/H3C.jpeg'
import hikvision from '../Components//Images/partners/HIKVISION.png'
import hp from '../Components/Images/partners/HP.png'
import hpe from '../Components/Images/partners/HPE.png'
import huawei from '../Components/Images/partners/HUAWEI.jpeg'
import ibm from '../Components/Images/partners/IBM.png'
import iota from '../Components/Images/partners/IOTA.png'
import kaspersky from '../Components/Images/partners/KASPERSKY.jpeg'
import kemp from '../Components/Images/partners/KEMP.png'
import lenovo from '../Components/Images/partners/LENOVO.png'
import microsoft from '../Components/Images/partners/MICROSOFT.png'
import mm from '../Components/Images/partners/MM.png'
import molex from '../Components/Images/partners/MOLEX.jpg'
import riverbed from '../Components/Images/partners/RIVERBED.png'
import ruckus from '../Components/Images/partners/RUCKUS.png'
import sanfor from '../Components/Images/partners/SANGFOR.png'
import sophos from '../Components/Images/partners/SOPHOS.png'
import trendnet from '../Components/Images/partners/TRENDnet.png'
import unv from '../Components/Images/partners/UNV.png'
import vericom from '../Components/Images/partners/VERICOM.png'
import vertiv from '../Components/Images/partners/VERTIV.png'
import vivanco from '../Components/Images/partners/VIVANCO.png'
import vmware from '../Components/Images/partners/VMWARE.jpg'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const OurPartners = () => {
  useEffect(() => {
    AOS.init({ duration: 3000 }); 
  }, []);
  return (
    <div>
      <Navbar/>
      <h1 style={{color: 'navy', fontFamily: 'sans-serif', textAlign: 'center', marginTop: '2%'}} data-aos="fade-up" data-aos-delay="500">Our Partners</h1>
      <hr style={{color: 'navy'}} />
        <div className='ourpartnersdiv' data-aos="fade-up" data-aos-delay="500">
          <div className='opr1' data-aos="fade-up" data-aos-delay="500">
            <div className='opb1'><img className='alhua' src={alhua} alt="alhua" /></div>
            <div className='opb1'><img className='attom' src={attom} alt="attom" /></div>
            <div className='opb1'><img className='awan' src={awan} alt="awan" /></div>
            <div className='opb1'><img className='aws' src={aws} alt="aws" /></div>
            <div className='opb1'><img className='brocade' src={brocade} alt="brocade" /></div>
          </div>

          <div className='opr2' data-aos="fade-up" data-aos-delay="500">
            <div className='opb1'><img className='citrix' src={citrix} alt="citrix" /></div>
            <div className='opb1'><img className='cloud9' src={cloud9} alt="cloud9" /></div>
            <div className='opb1'><img className='dellemc' src={dellemc} alt="dellemc" /></div>
            <div className='opb1'><img className='dellt' src={delltechnologies} alt="dellt" /></div>
            <div className='opb1'><img className='digitus' src={digitus} alt="digitus" /></div>
          </div>

          <div className='opr2' data-aos="fade-up" data-aos-delay="500">
            <div className='opb1'><img className='fortinet' src={fortinet} alt="fortinet" /></div>
            <div className='opb1'><img className='grandstream' src={grandstream} alt="grandstream" /></div>
            <div className='opb1'><img className='h3c' src={h3c} alt="h3c" /></div>
            <div className='opb1'><img className='hikvision' src={hikvision} alt="hikvision" /></div>
            <div className='opb1'><img className='hp' src={hp} alt="hp" /></div>
          </div>

          <div className='opr2' data-aos="fade-up" data-aos-delay="500">
            <div className='opb1'><img className='hpe' src={hpe} alt="hpe" /></div>
            <div className='opb1'><img className='huawei' src={huawei} alt="huawei" /></div>
            <div className='opb1'><img className='ibm' src={ibm} alt="ibm" /></div>
            <div className='opb1'><img className='iota' src={iota} alt="iota" /></div>
            <div className='opb1'><img className='kaspersky' src={kaspersky} alt="kaspersky" /></div>
          </div>

          <div className='opr2' data-aos="fade-up" data-aos-delay="500">
            <div className='opb1'><img className='kemp' src={kemp} alt="kemp" /></div>
            <div className='opb1'><img className='lenovo' src={lenovo} alt="lenovo" /></div>
            <div className='opb1'><img className='microsoft' src={microsoft} alt="microsoft" /></div>
            <div className='opb1'><img className='mm' src={mm} alt="mm" /></div>
            <div className='opb1'><img className='molex' src={molex} alt="molex" /></div>
          </div>

          <div className='opr2' data-aos="fade-up" data-aos-delay="500">
            <div className='opb1'><img className='riverbed' src={riverbed} alt="riverbed" /></div>
            <div className='opb1'><img className='ruckus' src={ruckus} alt="ruckus" /></div>
            <div className='opb1'><img className='sangfor' src={sanfor} alt="sanfor" /></div>
            <div className='opb1'><img className='sophos' src={sophos} alt="sophos" /></div>
            <div className='opb1'><img className='trendnet' src={trendnet} alt="trendnet" /></div>
          </div>

          <div className='opr2' data-aos="fade-up" data-aos-delay="500">
            <div className='opb1'><img className='unv' src={unv} alt="unv" /></div>
            <div className='opb1'><img className='vericom' src={vericom} alt="vericom" /></div>
            <div className='opb1'><img className='vertiv' src={vertiv} alt="vertiv" /></div>
            <div className='opb1'><img className='vivanco' src={vivanco} alt="vivanco" /></div>
            <div className='opb1'><img className='vmware' src={vmware} alt="vmware" /></div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}

export default OurPartners