import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import '../css/BusinessUnits.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faUser, faPrint, faRocket, faVideo, faBug, faCubes, faEnvelope, faDog } from '@fortawesome/free-solid-svg-icons'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const BusinessUnits = () => {
  useEffect(() => {
    AOS.init({ duration: 3000 }); 
  }, []);
  return (
    <div>
      <Navbar/>
        <h1 style={{textAlign: 'center', color: 'navy'}} data-aos="fade-up" data-aos-delay="500">Business Units</h1>
        <hr />
        <div className='businessunitsparentdiv' data-aos="fade-up" data-aos-delay="500">
          <div className='br'>
            <div className='bx'>
              <FontAwesomeIcon style={{fontSize: '200%', color: 'navy'}} icon={faClock}></FontAwesomeIcon>
              <h2 style={{color: 'navy', fontFamily: 'sans-serif', marginTop: '5%'}}>Automation</h2>
              <p style={{fontFamily: 'sans-serif', lineHeight: '250%'}}>Digitel is now offering CMS, ERP, LMS, MIS, CRM, HRMS, Inventory Management System & Website Development in its automation domain.</p>
            </div>
            <div className='bx'>
              <FontAwesomeIcon style={{fontSize: '200%', color: 'navy'}} icon={faUser}></FontAwesomeIcon>
              <h2 style={{color: 'navy', fontFamily: 'sans-serif', marginTop: '5%'}}>Cloud Solutions</h2>
              <p style={{fontFamily: 'sans-serif', lineHeight: '250%'}}>DigiTel offers the most advanced cloud solutions that help organizations drive innovation and business transformation by increasing business agility, lowering costs, and reducing IT complexity. Its cloud portfolio includes SAAS, PAAS & IAAS</p>
            </div>
            <div className='bx'>
              <FontAwesomeIcon style={{fontSize: '200%', color: 'navy'}} icon={faPrint}></FontAwesomeIcon>
              <h2 style={{color: 'navy', fontFamily: 'sans-serif', marginTop: '5%'}}>Virtualization Solutions</h2>
              <p style={{fontFamily: 'sans-serif', lineHeight: '250%'}}>DigiTel deliveres VMware implementation solutions & 24x7 on-site/off-site support to organizations of all sizes. JBS is the principal partner for VMware in the area of Datacenter Virtualization, the first and only partner in Pakistan. This is the highest level of partnership.</p>
            </div>
          </div>

          <div className='br2' data-aos="fade-up" data-aos-delay="500">
            <div className='bx'>
              <FontAwesomeIcon style={{fontSize: '200%', color: 'navy'}} icon={faRocket}></FontAwesomeIcon>
              <h2 style={{color: 'navy', fontFamily: 'sans-serif', marginTop: '5%'}}>Cyber Security Solutions</h2>
              <p style={{fontFamily: 'sans-serif', lineHeight: '250%'}}>In order to provide your business with a secure foundation, cybersecurity is a requirement. Digitel has a vast portfolio of cybersecurity services that aim to help organizations address the security challenges through a more proactive approach.</p>
            </div>
            <div className='bx'>
            <FontAwesomeIcon style={{fontSize: '200%', color: 'navy'}} icon={faVideo}></FontAwesomeIcon>
            <h2 style={{color: 'navy', fontFamily: 'sans-serif', marginTop: '5%'}}>Technology Solutions</h2>
            <p style={{fontFamily: 'sans-serif', lineHeight: '250%'}}>By updating your applications and IT infrastructure, DigiTel can help you reduce your business costs and move towards a more sustainable business model. We've partnered up with only the best OEMs for infrastructure services as well as enterprise solutions.</p>
            </div>
            <div className='bx'>
            <FontAwesomeIcon style={{fontSize: '200%', color: 'navy'}} icon={faBug}></FontAwesomeIcon>
            <h2 style={{color: 'navy', fontFamily: 'sans-serif', marginTop: '5%'}}>Frontend Solutions</h2>
            <p style={{fontFamily: 'sans-serif', lineHeight: '250%'}}>When it comes to front-end devices we aim to provide you with excellent quality and powerful hardware. We're able to provide you with limitless options by offering you the complete grandstream portfolio</p>
            </div>
          </div>

          <div className='br2' data-aos="fade-up" data-aos-delay="500">
            <div className='bx'>
            <FontAwesomeIcon style={{fontSize: '200%', color: 'navy'}} icon={faCubes}></FontAwesomeIcon>
            <h2 style={{color: 'navy', fontFamily: 'sans-serif', marginTop: '5%'}}>Social Media Marketing</h2>
            <p style={{fontFamily: 'sans-serif', lineHeight: '250%'}}>Innovation is at the heart of everything we do. DigiTel enables you to scale your business through digital services and cutting-edge technology. By helping you build your digital strategy we bring you one step closer to creating disruption and moving beyond competitive advantage.</p>
            </div>
            <div className='bx'>
            <FontAwesomeIcon style={{fontSize: '200%', color: 'navy'}} icon={faEnvelope}></FontAwesomeIcon>
            <h2 style={{color: 'navy', fontFamily: 'sans-serif', marginTop: '5%'}}>Data Center Solutions</h2>
            <p style={{fontFamily: 'sans-serif', lineHeight: '250%'}}>Being a tier Authorized solutions & service provider of Vertiv, DigiTel offers an entire range of data center-related products including but not lmited to Power, HVAC (Heating, Ventilation, and air conditioning), Racks & Enclosures/Cold Aisle containment, environmental monitoring systems, and UPS/Rectifiers.</p>
            </div>
            <div className='bx'>
            <FontAwesomeIcon style={{fontSize: '200%', color: 'navy'}} icon={faDog}></FontAwesomeIcon>
            <h2 style={{color: 'navy', fontFamily: 'sans-serif', marginTop: '5%'}}>Infrastructure Solutions</h2>
            <p style={{fontFamily: 'sans-serif', lineHeight: '250%'}}>Being a Tier 1 partner of HPE, we are able to offer the complete range of HPE's portfolio to our customers while providing them with full support from installation to maintainence. Our product specialists bring you 24/7 on-sight and off-sight support to ensure effeciancy and improved agility</p>
            </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}

export default BusinessUnits
