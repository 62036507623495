import React, {useEffect, useState} from 'react'
import logosplash from '../Components/Images/logoblue.png'
import '../css/SplashScreen.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../css/Main.css';

const SplashScreen = ({startAnimation}) => {
    const [showSplash, setShowSplash] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setShowSplash(false);
        }, 5000);
    }, []);

    useEffect(() => {
        AOS.init({ duration: 1500 });
      }, []);
  return (
    showSplash && (
        <div className={`splash-screen ${startAnimation ? 'slide-out' : ''}`} data-aos="fade-up" data-aos-delay="500">
            <img src={logosplash} alt="splashlogo" className='splashlogo' />
            <h1>Welcome to DigiTel Integrated Solutions</h1>
        </div>
    )
  )
}

export default SplashScreen